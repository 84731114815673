import * as $ from 'jquery';
import 'owl.carousel';
import 'nouislider';
import './typeahead';
import './checkout';
import './rangeslider';



/**
 * *************************************************
 * ============== Owl Carousel sliders =============
 * *************************************************
 */
$('#owl-clients').owlCarousel({
    items: 3,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:3
        }
    },
    autoplay:true,
    dots: false,
    nav: false,
    autoplayTimeout:5000,
    autoplayHoverPause:true
});













// Cookies
$.fn.extend({
    toggleText: function(stateOne, stateTwo) {
        return this.each(function() {
            stateTwo = stateTwo || '';
            $(this).text() !== stateTwo && stateOne ? $(this).text(stateTwo)
                : $(this).text(stateOne);
        });
    }
});
function setCookie(name,value,days) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
    }
    else expires = '';
    document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

/**
 * Recent viewed products on cookies
 */
if( $('.single-product').length ){
    var prodId = $('.product-identifier').data('id');
    setCookie('recentproduct_'+prodId,'viewed',3);
}

/**
 * Delets cookie by key
 * @param name
 */
function deleteCookie(name) {
    setCookie(name,"",-1);
}

function hasVoted(dataId){
    var userVoted = readCookie('user_voted_'+ dataId);
    return !!userVoted;

}

function hasClicked(data){
    var userClicked = readCookie('user_clicked_'+ data);
    return !!userClicked;

}



var userip = readCookie("userip");

if (!userip){
    $('#warning_popup').fadeIn();
}
else{
    $('#warning_popup').fadeOut();
}

$('#warning_popup_close').on('click', function(){
    var now = new Date();
    now.setTime(now.getTime() + 24 * 3600 * 1000);
    var ip = $("input[name='ip']").val();
    document.cookie = "userip=" + ip +";path=/;expires="+ now.toUTCString();
    $('#warning_popup').fadeOut();
});

/*Scroll top*/
$(window).scroll(function(){
    if ($(this).scrollTop() > 100) {
        $(".to-top").addClass('active');
    }
    else {
        $(".to-top").removeClass('active');
    }
});

/*Scroll top Click*/
$(".to-top").on("click", function () {
    $('html, body').animate({scrollTop: 0}, 1000);
});




function isScrolledIntoView(elem){
  if(elem.length !== 0){
    var $elem = $(elem);
    var $window = $(window);
    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();
    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();
    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }
  else{
    return false;
  }
}

$(document).ready(function(){



    // --------- Gallery Likes clicks ------------------
    $('.js-like').on('click',function(){
        var currentItem = $('.gallery-item-js.active');
        var currentPostId = parseInt(currentItem.data('post-id'));
        var activeSlide = parseInt(currentItem.data('order'));
        var srcUrl = currentItem.data('src-url');
        var currentLikes = parseInt(currentItem.data('likes'));
        if( !hasClicked(srcUrl) ){
            $.ajax({
                type: 'POST',
                url: likesScript.url,
                data:{
                    action: likesScript.action,
                    nonce: likesScript._wp_nonce,
                    post_id: currentPostId,
                    active_slide: activeSlide,
                    current_likes: currentLikes
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    if( res.status === 'success' ){
                        $('.total-virtual-likes').text(currentLikes+1);
                        currentItem.find('.likes-total').text(currentLikes+1);
                        setCookie('user_clicked_'+ srcUrl,'clicked',5);
                    }else{
                        console.log(res.status);
                    }
                }
            });
        }else{
            console.log('You have already clicked this photo');
        }

    });

    var siteBody = $("body");
    var siteHtml = $("html");
    /**
     * ********************************************************
     * ---------------------Scroll Effects---------------------
     * ========================================================
     * */

    /*
    (function($) {
        var clientHeight = $(window).height();
        var items = [];
        $(document).scroll(function() {
            for(var i = 0; i < items.length; i++) {
                if(items[i].ele.offset().top - $(document).scrollTop() - clientHeight < 350) {
                    if(typeof items[i].arg === 'string') {
                        items[i].ele.addClass(items[i].arg);
                    } else if(items[i].arg instanceof Function) {
                        items[i].arg.call();
                    }
                }
            }
        });
        jQuery.fn.extend({
            listenedByScroll: function(arg) {
                jQuery(document).scroll();
                if(!arg) return this;
                items.push({ele: $(this), arg: arg});
                return this;
            }
        });
    })(jQuery);*/

    /*Menu*/

    $(".trigger").on('click',function () {
        $('.top-menu').slideToggle();
        $('.top-search').slideToggle();
        $('.top-widget').slideToggle();
        $(this).toggleClass("opened");
    });
    function a(t, e) {
        var o = $(".animate-number-js");
        setTimeout(function() {
            $(o[t]).text(e)
        }, 100 + 20 * e)
    }
    $(".animate-number-js").length && $(window).scroll(function() {
        var t = $(".animate-number-js"),
            e = $(".animate-number").offset().top;
        if ($(window).scrollTop() + $(window).height() > e + 100) {
            for (var o = 0; o < t.length; o++)
                for (var n = parseInt($(t[o]).data("rating")), i = 0; i < n + 1; i++)
                    a(o, i);
            $(t).removeClass("animate-number-js")
        }
    });
    // To products button scroll
    $(".to-products-dirrection").click(function(e){
        var t=$(".shop-result-count").offset();
        $("html, body").animate({scrollTop:t.top - 80},500)});


    // Ajax add to card
    $('.add-to-cart-js').on('click', function(){
        if( !$(this).hasClass('added') ){
            $(this).addClass('added');
            var cartNumber = $('#cart_number');
            var cartTotal = $('#cart_total');
            var productId = $(this).data('id');
            var price = $(this).data('price');
            var actualCartCount = parseInt(cartNumber.text());
            var actualTotalPrice = parseInt(cartTotal.text());
            setCookie('product_'+productId,'added_'+price,5);
            cartNumber.text(actualCartCount + 1);
            cartTotal.text(actualTotalPrice + parseInt(price));
        }else if( $(this).hasClass('added') ){
            var cartUrl = $('.cart-count').attr('href');
            location.assign(cartUrl);
        }
    });

    // Ajax remove from card
    $('.remove').on('click',function(){
        var dataRemove = $(this).data('remove');
        var dataRemovedPrice = parseInt($(this).data('removed-price'));
        var $cartNumber = $('#cart_number');
        var $cartTotal = $('#cart_total');
        var cartNumber = $cartNumber.text();
        var cartTotal = $cartTotal.text();
        deleteCookie('product_'+ dataRemove);
        $(this).closest('.cart-item').remove();
        $cartNumber.text( parseInt(cartNumber) - 1);
        var cartSumUpdate = parseInt(cartTotal) - dataRemovedPrice;
        $('#cart_subtotal').text(cartSumUpdate);
        $cartTotal.text( cartSumUpdate );
        if( cartSumUpdate === 0){
            var shopPageUrl = $('#cart_content').data('shop-url');
            $('#cart_content').html("<h3>Ваш кошик порожній</h3><p>Для здійснення покупок, будь-ласка, спершу перейдіть до сторінки <a href='"+shopPageUrl+"'>ART-крамниця</a>.</p>");
        }
    });

    /**
     * Cart checkout form
     */
    $('#carts_total_form').on('submit',function(e){
        e.preventDefault();
        var checkoutUrl = $(this).data('checkout-url');
        var checkoutMethod = $(this).find("input[name='checkout_method']:checked").val();
        location.assign(checkoutUrl+'?checkout_method='+checkoutMethod);
    });

    // Load more recommended products
    $(window).on('scroll',function(e){
        e.preventDefault();
        e.stopPropagation();

        var loadMore = $('#load_more_recommended_products');
        if( loadMore.length > 0 ){
            var loadMoreOffsetTop = loadMore.offset().top;
            var hiddenIds = $(loadMore).data('hidden-id');
            var data = {
                'action': 'load_more_recommended_products',
                'hidden_id': hiddenIds
            };
            if( $(document).scrollTop() > loadMoreOffsetTop - 600 && !$('body').hasClass('loading')){
                $.ajax({
                    url:localizedScript.url,
                    data:data,
                    type:'POST',
                    beforeSend: function( xhr){
                        $('body').addClass('loading');
                    },
                    success:function(data){
                        if(data){
                            var response = JSON.parse(data);
                            if( response.status === 'success' ) {
                                $('.recommended-products').append(response.html);
                                loadMore.data('hidden-id',response.hidden_id);
                                $('body').removeClass('loading');
                                console.log(response.hidden_id);
                            }
                            if( !response.hidden_id || response.hidden_id === '' ){
                                loadMore.remove();
                            }
                        }

                    }
                });
            }
        }
    });

    $('#load_more_recommended_products').on('click',loadMoreProducts);
    function loadMoreProducts(e){
        e.preventDefault();
        e.stopPropagation();
        var loadMore = $('#load_more_recommended_products');
        var hiddenIds = $(this).data('hidden-id');
        var data = {
            'action': 'load_more_recommended_products',
            'hidden_id': hiddenIds
        };
        $.ajax({
            url:localizedScript.url,
            data:data,
            type:'POST',
            success:function(data){
                if(data){
                    var response = JSON.parse(data);
                    if( response.status === 'success' ) {
                        $('.recommended-products').append(response.html);
                        loadMore.data('hidden-id',response.hidden_id);
                        console.log(response.hidden_id);
                    }
                    if( !response.hidden_id || response.hidden_id === '' ){
                        loadMore.remove();
                    }
                }

            }
        });
    }

    /**
     * =================================================================================================================
     * ***************************************** Filter form *****************************************************
     * =================================================================================================================
     */

    /**
     * ---------------- Filter form UI -------------
     */

    $('.js-mobile-filter-trigger').on('click',function(){
        $('.products-filter').toggle(500);
        $(this).toggleClass("opened closed");
    });

    $('.legend-trigger-js').on('click',function(){
        $(this).toggleClass("closed");
        $(this).parent().find('.items-group-js').slideToggle();
    });

    $('.show-more-prod-cat-btn-js').on('click',function(){
        $(this).hide();
        $(this).parent().find('.checkbox-group-item-hidden').slideDown();
        $(this).parent().find('.show-less-prod-cat-btn-js').show();
    });
    $('.show-less-prod-cat-btn-js').on('click',function(){
        $(this).hide();
        $(this).parent().find('.checkbox-group-item-hidden').slideUp();
        $(this).parent().find('.show-more-prod-cat-btn-js').show();
    });


    $('#filter_form').on('change',function(){

        // var url = window.location.protocol + '//' + window.location.host;
        var currentUrl = window.location.href.split('?')[0]; //$('body').data('home-url');
        var data = $(this).serialize();
        var filterUrl = currentUrl+'?'+data;
        console.log(filterUrl);
        window.location.replace(filterUrl);
    });

    $('.filter-reset-btn-js').on('click',function(){
        var currentUrl = window.location.href.split('?')[0];
        window.location.replace(currentUrl);
    });




    /*$('.main-menu li.menu-item-has-children').append('<span class="subMenuButton fa fa-angle-down"></span>');
    $('.subMenuButton').on('click',function () {
        var t = $(this);
        if(t.hasClass('open')) {
            t.removeClass('open').prev().slideUp(300);
        } else {
            t.removeClass('open').prev().slideDown(300);
            t.addClass('open');
        }
    });*/
    //Menu Close
    $(document).mouseup(function (e){
        var div = $(".main-menu,#menuOpen");
        if (!div.is(e.target)
            && div.has(e.target).length === 0) {
            div.removeClass('opened');
            $('.hamburger').removeClass('is-active');
            //siteBody.removeClass('shadow');
            //siteHtml.removeClass('shadow');
        }
    });

    /*Scroll top*/
    $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $(".to-top-btn").addClass('active');
        }
        else {
            $(".to-top-btn").removeClass('active');
        }
        /*if ( $(this).width() > 991 ) {
            var footer = $(".footer").offset().top;
            var sidebar = $(".shop-sidebar-js");
            if( $(this).scrollTop() + $(this).height() > footer ){
                $(sidebar).removeClass("fixed").addClass("absolute");
            }else{
                $(sidebar).removeClass("absolute").addClass("fixed");
            }
        }*/
    });

    /*Scroll top Click*/
    $(".to-top-btn").on("click", function () {
        $('html, body').animate({scrollTop: 0}, 1000);
    });



    // 404 go back
    $('.back').on('click', function(){
        setTimeout('history.back()', 1000);
    });

    /**
     * Displaying comment by click
     */
    var $body = $('body');
    $body.on('click','.show-comments-js',function(){
        $('#comments').slideToggle();
        $(this).find('i').toggleClass("fa-caret-up fa-caret-down");
        $(this).find('.show-comments-text-js').toggleText('Приховати коментарі','Показати коментарі');
    });

    /**
     * Comments rating
     */
    $body.on('change',"input[name='rating']",function(){
        var rating = parseInt($(this).val());
        $('.heart-keeper').each(function(i){
            if( i+1 <= rating ){
                $(this).find('i').removeClass('fa-star-o').addClass('fa-star');
            }else{
                $(this).find('i').removeClass('fa-star').addClass('fa-star-o');
            }
        })
    });

    /**
     * Sorting products
     */
    $('.sort-args-js').on('click',function(){
       var getParam = $(this).data('sortby');
       $('.sort-args-js').removeClass('active');
       $(this).addClass('active');
       location.assign(getParam);
    });


    /**
     *  ============= Gallery Slider ==================
     * ************************************************
     */
    var lastX,
        run,
        allItems = $('.g-gallery-item').length - 1;
    var gallerySlide = $('#gallery_slide');
    // Exit from gallery
    $('.g-exit-js').on('click',function(){
        $('#g_play').removeClass('disabled active').addClass('enabled');
        $('#g_stop').removeClass('disabled active');
        clearInterval(run);
        $('#gallery_popup_slider').fadeOut();
    });

    // Exit from gallery by click outside
    $('#gallery_popup_slider').on('click',function(event){
        if (!$(event.target).closest('.gallery-navigation').length && !$(event.target).closest("#gallery_slide img").length) {
            $('#g_play').removeClass('disabled active').addClass('enabled');
            $('#g_stop').removeClass('disabled active');
            clearInterval(run);
            $("#gallery_popup_slider").fadeOut();
        }
    });

    // Prev and Next slide by touch move in mobiles
    gallerySlide.bind('touchstart','img', function(e) {
        lastX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
    });
    gallerySlide.bind('touchmove','img', function(e) {
        var currentX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
        if (Math.abs(currentX-lastX) < 30) { return; }
        if (currentX > lastX) {
            prevSlideAction();
        } else {
            nextSlideAction();
        }
    });


    // Previous slide event handler
    $('#g_prev').on('click',prevSlideAction);

    function prevSlideAction(){
        var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
        var prevSlide = getPrevSlide(activeSlide);
        $('.gallery-item-js').removeClass('active');
        var prevObj =  $('.g-gallery-item').eq(prevSlide);
        $(prevObj).addClass('active');
        generateSlideMarkup(prevObj);
    }

    function getPrevSlide(activeSlide){
        if(activeSlide > 0){
            return activeSlide-1;
        }
        if(activeSlide === 0){
            return allItems;
        }
    }

    // Next slide
    $('#g_next').on('click',nextSlideAction);

    function nextSlideAction(){
        var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
        var nextSlide = getNextSlide(activeSlide);
        $('.gallery-item-js').removeClass('active');
        var nextObj =  $('.g-gallery-item').eq(nextSlide);
        $(nextObj).addClass('active');
        generateSlideMarkup(nextObj);
    }

    function getNextSlide(activeSlide){
        if(activeSlide < allItems){
            return activeSlide+1;
        }
        if(activeSlide === allItems){
            return 0;
        }
    }

    $('.g-control').on('click',function(){
        $('.g-control').removeClass('active');
        $(this).addClass('active');
    });

    $('#g_play').on('click',function(){
        if( $(this).hasClass('enabled') ){
            run = setInterval(function(){ gallerySliderRun()},3000);
            $(this).removeClass('enabled').addClass('disabled');
        }
    });

    $('#g_stop').on('click',function(){
        $('#g_play').removeClass('disabled').addClass('enabled');
        clearInterval(run);
    });

    function gallerySliderRun(){
        if( !$('.gallery-items').find('#gallery-navigation').hasClass('stop') ){
            var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
            var nextSlide = getNextSlide(activeSlide);
            $('.gallery-item-js').removeClass('active');
            var nextObj =  $('.g-gallery-item').eq(nextSlide);
            $(nextObj).addClass('active');
            generateSlideMarkup(nextObj);
        }
    }
    $('#g_info').on('click',function(){
        $(this).toggleClass('active');
        $('#gallery_slide_info').toggle();
    });

    /**
     * Calling generateSlideMarkup on gallery item block click
     * ignoring <a> tag
     */
    $('.gallery-item-js').on('click',function(e){
        var $target = $(e.target);
        var IGNORE_SELECTOR = 'a';
        if ( !$target.is(IGNORE_SELECTOR) ) {
            $('.gallery-item-js').removeClass('active');
            $(this).addClass('active');
            generateSlideMarkup(this);
        }
    });

    /**
     * Generating markup inside popup
     * @param obj
     */
    function generateSlideMarkup(obj){
        var dataUrl = $(obj).data('ph-product-object-url');
        var dataSrc = $(obj).data('src-url');
        var dataPhTitle = $(obj).data('ph-title');
        var dataPhDescription = $(obj).data('ph-description');
        var dataLikes = $(obj).data('likes');
        $('#gallery_popup_slider').fadeIn();
        $('#gallery_slide').html("<img src='"+dataSrc+"'" + " alt='slide' >");
        if ( dataUrl && dataPhTitle && dataPhDescription ){
            $('#gallery_slide_info').html('<h4><a href="'+dataUrl+'">'+dataPhTitle+'</a></h4>'+'<p><i class="fa fa-heart" aria-hidden="true"></i><span class="total-virtual-likes">'+dataLikes+'</span></p><p>'+dataPhDescription+'</p>');
        }
    }

    /**
     * **************************************************************
     */

        // Product owl slider

        // reference for main items
    var slider = $('#product_main_slider');
    // reference for thumbnail items
    var thumbnailSlider = $('#product_thumbnail_slider');
    //transition time in ms
    var duration = 500;

    // carousel function for main slider
    slider.owlCarousel({
        loop: false,
        nav: false,
        items:1
    }).on('changed.owl.carousel', function (e) {
        //On change of main item to trigger thumbnail item
        thumbnailSlider.trigger('to.owl.carousel', [e.item.index, duration, true]);
    });

    // carousel function for thumbnail slider
    thumbnailSlider.owlCarousel({
        loop: false,
        nav: false,
        items:4
    }).on('click', '.owl-item', function () {
        // On click of thumbnail items to trigger same main item
        slider.trigger('to.owl.carousel', [$(this).index(), duration, true]);

    }).on('changed.owl.carousel', function (e) {
        // On change of thumbnail item to trigger main item
        slider.trigger('to.owl.carousel', [e.item.index, duration, true]);
    });


    //These two are navigation for main items
    $('.slider-right-js').click(function() {
        slider.trigger('next.owl.carousel');
    });
    $('.slider-left-js').click(function() {
        slider.trigger('prev.owl.carousel');
    });

    /**
     * **********************************************
     * ********** Product Slider ********************
     * **********************************************
     */
    var $productSliderItemJS = $('.product-slider-item-js');
    var $sliderItemJs = $('.slider-item-js');
    var pvp_scaled = $('#pvp_scaled');
    var last_x;
    var allSlides = $sliderItemJs.length - 1;
    $('.pvp-exit-js').on('click',function(){
        $('#popup_view_port').fadeOut('fast');
    });


    // Zoom out
    $('.pvp-zoom-js').on('click',function(){
        $('.zoom').removeClass('grab').addClass('zoom-in').css(
            {
                'background-size': 'cover',
                'background-position':'inherit'
            });
        $(this).fadeOut();
    });
    // Exit from gallery by click outside
    $('#popup_view_port').on('click',function(e){
        if( !$(e.target).closest('.pvp-navigation').length && !$(e.target).closest(".zoom").length && !$(e.target).closest(".pvp-zoom").length ) {
            $('#popup_view_port').fadeOut('fast');
        }
    });


    $sliderItemJs.on('click',function(){
        $('.product-slider-item').removeClass('active');
        $(this).addClass('active');
        generateProductSlideMarkup(this);
    });

    var $sliderViewPort = $('#slider_view_port');

    $sliderViewPort.on('touchstart','img',function(e){
        last_x = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
    });
    $sliderViewPort.on('touchmove','img',function(e){
        var currentX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
        if (Math.abs(currentX-last_x) < 30) { return; }
        if (currentX > last_x) {
            //previousSlideAction();
        } else {
            //next_SlideAction();
        }
    });

    $sliderViewPort.on('click',function(){
        $('#popup_view_port').show('fast');
        var actualSlide = $('.slider-item-js.active');
        var srcUrl = actualSlide.data('src-url');
        $('#pvp_scaled').html("<div class='zoom zoom-in zoom-js' style='background-image: url("+srcUrl+");'></div>");
    });
    $productSliderItemJS.on('click',function(){
        $('#popup_view_port').show('fast');
        var actualSlide = $(this);
        var srcUrl = actualSlide.data('src-url');
        $('#pvp_scaled').html("<div class='zoom zoom-in zoom-js' style='background-image: url("+srcUrl+");'></div>");
    });

    // Prev and Next slide by touch move in mobiles
    pvp_scaled.bind('touchstart','img', function(e) {
        last_x = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
    });
    pvp_scaled.bind('touchmove','img', function(e) {
        var currentX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
        if (Math.abs(currentX-last_x) < 30) { return; }
        if (currentX > last_x) {
            //previousSlideAction();
            slider.trigger('prev.owl.carousel');
            $('.pvp-zoom-js').hide();
            var prevObj = $('#product_main_slider').find('.active').find('.product-slider-item-js');
            generateProductSlideMarkup(prevObj);
        } else {
            //next_SlideAction();
            slider.trigger('next.owl.carousel');
            $('.pvp-zoom-js').hide();
            var nextObj = $('#product_main_slider').find('.active').find('.product-slider-item-js');
            generateProductSlideMarkup(nextObj);
        }
    });
    // Previous slide event handler
    $('#pvp_prev').on('click',previousSlideAction);
    $('#pvp_prev_js').on('click',function(){
        $('.pvp-zoom-js').hide();
        var prevObj = $('#product_main_slider').find('.active').find('.product-slider-item-js');
        generateProductSlideMarkup(prevObj);
    });

    function previousSlideAction(){
        $('.pvp-zoom-js').hide();
        var activeSlide = parseInt($('.slider-item-js.active').data('order'));
        var prevSlide = getPrev_Slide(activeSlide);
        $sliderItemJs.removeClass('active');
        var prevObj = $sliderItemJs.eq(prevSlide);
        $(prevObj).addClass('active');
        generateProductSlideMarkup(prevObj);
    }
    function getPrev_Slide(activeSlide){
        if(activeSlide > 0){
            return activeSlide-1;
        }
        if(activeSlide === 0){
            return allSlides;
        }
    }

    // Next slide event handler
    $('#pvp_next').on('click',next_SlideAction);
    $('#pvp_next_js').on('click',function(){
        $('.pvp-zoom-js').hide();
        var nextObj = $('#product_main_slider').find('.active').find('.product-slider-item-js');
        generateProductSlideMarkup(nextObj);
    });
    function next_SlideAction(){
        $('.pvp-zoom-js').hide();
        var activeSlide = parseInt($('.slider-item-js.active').data('order'));
        var nextSlide = getNext_Slide(activeSlide);
        $sliderItemJs.removeClass('active');
        var nextObj = $sliderItemJs.eq(nextSlide);
        $(nextObj).addClass('active');
        generateProductSlideMarkup(nextObj);
    }
    function getNext_Slide(activeSlide){
        if( activeSlide < allSlides ){
            return activeSlide+1;
        }
        if( activeSlide === allSlides ){
            return 0;
        }
    }
    var $bg = $();
    function generateProductSlideMarkup(obj){
        var dataSrc = $(obj).data('src-url');
        var dataPhTitle = $(obj).data('ph-title');
        var sliderViewPort = $('#slider_view_port');
        $('#pvp_scaled').html("<div class='zoom zoom-in zoom-js' style='background-image: url("+dataSrc+");'></div>");
        sliderViewPort.html("<img src='"+dataSrc+"'" + "alt='slide' >");
    }


    /**
     * Zoom effect with drug
     * @type {*|jQuery|HTMLElement}
     */
    var pvpScaled = $('.pvp-scaled');
// Differentiate click and mouse down and up
    var holdStarter = null;
    var holdActive = false;
    var origin = {x: 0, y: 0},
        start = {x: 0, y: 0},
        movecontinue = false;
    var bgSize = 0;
    var ratioWH = 0;



    pvpScaled.on('click','.zoom-in',function(e){
        if( $(window).width() > 991 ){
            $('.pvp-zoom-js').fadeIn();
            var boxWidth = $(this).width();
            var boxHeight = $(this).height();
            var ratioHW =  boxHeight / boxWidth;
            ratioWH = boxWidth / boxHeight;
            var xPos = e.pageX - this.offsetLeft;
            var yPos = e.pageY - this.offsetTop;
            console.log(xPos + ' ' + yPos);
            console.log(boxWidth + ' ' + boxHeight);
            var proportionX = boxWidth/xPos;
            var proportionY = boxHeight/yPos;
            console.log(proportionX + ' ' + proportionY);
            $(this).css({
                'background-size': $(this).width() * 3 + 'px'
            });
            bgSize = $(this).width() * 3;
            $(this).removeClass('zoom-in').addClass('grab');
            var boxZoomedWidth = $(this).width();
            var boxZoomedHeight = $(this).height();
            console.log(boxZoomedWidth + '/'+ boxZoomedHeight);
            console.log(boxZoomedWidth/proportionX + ' ' + boxZoomedHeight/proportionY );
            var diffX = bgSize/boxZoomedWidth;
            var bgSizeY = bgSize/ratioWH;
            var diffY = bgSizeY/boxZoomedHeight;
            $(this).css({
                'background-position': xPos*-(1.33)+ 'px' + ' ' + yPos*-(1.93)+ 'px'
            });

            start = {x: (xPos)*-(1.33), y: (yPos)*-(1.93)};
        }

    });

    $(document).on('mousedown mouseup','.grab',handle);

    function move(e) {
        $bg = $(document).find('.grab');
        var offset = {
            x: start.x - (origin.x - e.clientX),
            y: start.y - (origin.y - e.clientY)
        };
        if( movecontinue ) {
            start.x = offset.x;
            start.y = offset.y;
            $bg.css({'background-position': start.x + 'px ' + start.y + 'px'});
        }
        origin.x = e.clientX;
        origin.y = e.clientY;
        e.stopPropagation();
        return false;
    }

    function handle(e){
        origin.x = e.clientX;
        origin.y = e.clientY;
        $bg = $(document).find('.grab');
        $bg.unbind('mousemove', move);
        if (e.type === 'mousedown') {

            holdStarter = setTimeout(function() {
                holdStarter = null;
                holdActive = true;
            }, 200);

            $bg.addClass('grabbing');
            origin.x = e.clientX;
            origin.y = e.clientY;
            movecontinue = true;

            $bg.bind('mousemove', move);
        }
        if(e.type === 'mouseup'){

            if( start.x > 0 ){
                start.x = 0;
            }
            if( start.y > 0 ){
                start.y = 0;
            }
            if (start.x < (bgSize*-1) + $(window).width() ){
                start.x = (bgSize*-1) + $(window).width();
            }
            if (start.y < (bgSize/ratioWH)*-1 + $(window).height() ){
                start.y = (bgSize/ratioWH)*-1 + $(window).height();
            }

            $bg.css({'background-position': start.x + 'px ' + start.y + 'px'});
            console.log(start);
            if( holdStarter ) {
                clearTimeout(holdStarter);
            }
            else if( holdActive ) {
                holdActive = false;
            }
            $bg.removeClass('grabbing');
        }
        e.stopPropagation();
        return false;
    }

    pvpScaled.on('click','.grab', function(){
        if( holdStarter ){
            $('.pvp-zoom-js').fadeOut();
            $(this).css({
                'background-position': 'inherit',
                'background-size': 'cover'
            });
            $(this).removeClass('grab').addClass('zoom-in');
        }
    });




































    /**
     * +++++++++++++++++++++++++++++++++++++++++++++++++
     * ===================== Ajax solutions ============
     * +++++++++++++++++++++++++++++++++++++++++++++++++
     */

    // Loads comments by Ajax

    $('.load-comments-js').on('click',function(e){
        e.preventDefault();
        var currentId = $(this).data('current-id');
        $.ajax({
            method: 'POST',
            url: localizedScript.url,
            data: {
                action: 'rw_load_comments',
                postID: currentId
            },

            success: function( data ) {
                $( '#comments_wrapper' ).html( data );
                $('#load_comments').hide();
            }
        });
    });


    // Load More
    $('#rw_show_more').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var showMore = $('#rw_show_more');
            var loader = $('#loader');
            var showMoreText = showMore.text();
            var maxNumPages = $(this).data('max-pages');
            var queryVars = $(this).data('query-vars');
            var currentPage = $(this).data('current-page');
            var data = {
                'action': 'loadmore',
                'query_vars': queryVars,
                'page' : currentPage
            };
            showMore.hide();
            loader.show();
            $.ajax({
                url:localizedScript.url,
                data:data,
                type:'POST',
                success:function(data){
                    if( data ) {
                        $('.load-more-wrapper').before(data);
                        showMore.data('current-page',currentPage + 1);
                        showMore.show();
                        loader.hide();
                        if (currentPage === maxNumPages){
                            showMore.parent().hide();
                            showMore.remove();
                            loader.hide();
                        }
                    } else {
                        showMore.parent().hide();
                        showMore.remove();
                        loader.hide();
                    }
                }
            });
        });

    /**
     * *************************************************
     * ================ FORMS ==========================
     * *************************************************
     */
     // hero form
    $('#hero_cta_form').on('submit',function(e){
        e.preventDefault();
        var hcNonce = $(this).find("input[name='hero_nonce']").val();
        var hcName = $('#hero_cta_name').val();
        var hcEmail = $('#hero_cta_email').val();
        var hcService = $('#hero_cta_service').val();
        var hcMsg = $('#hero_cta_msg').val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(hcEmail);
        if(hcName === ''){
            $("#hero_cta_name").css({'border':'1px solid red'});
            $("#hero_cta_name_error").text('Name is required');
        }else{
            $("#hero_cta_name").css({'border':'1px solid #80060a'});
            $("#hero_cta_name_error").text('');
        }
        if( hcEmail === '' ){
            $("#hero_cta_email").css({'border':'1px solid red'});
            $("#hero_cta_email_error").text('Email is required');
        }else if( check_mail !== true ){
            $("#hero_cta_email").css({'border':'1px solid red'});
            $("#hero_cta_email_error").text('Not valid email');

        }else{
            $("#hero_cta_email").css({'border':'1px solid #80060a'});
            $("#hero_cta_email_error").text('');
        }
        if( hcName !== '' && check_mail === true ){
            $("#hero_cta_email").css({'border':'1px solid #80060a'});
            $("#hero_cta_email_error").text('');
            $("#hero_cta_name").css({'border':'1px solid #80060a'});
            $("#hero_cta_name_error").text('');
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'hero_cta_form',
                    nonce: hcNonce,
                    user_name: hcName,
                    user_email: hcEmail,
                    user_service: hcService,
                    user_message: hcMsg,
                    captcha: grecaptcha.getResponse()
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    console.log(result);
                    if(res.status === 'success'){
                        $('#hero_cta_form')[0].reset();
                        grecaptcha.reset();
                        $('.hero-thanks-popup').css({'display':'flex'});
                        setTimeout(function(){
                            $('.hero-thanks-popup').fadeOut();
                            $('.hero-cta-form-wrapper').fadeOut();
                        }, 3000);
                    }
                    else if(res.status === 'failed'){
                        $('.recaptcha-error-msg').html('<p>Oops... Validation is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });
    $('.hero-cta-js').on('click',function(){
        $('.hero-cta-form-wrapper').fadeIn();
        siteBody.addClass('shadow');
        siteHtml.addClass('shadow');

    });
    $('.hero-form-close-js').on('click',function(){
        $('.hero-cta-form-wrapper').fadeOut();
        siteBody.removeClass('shadow');
        siteHtml.removeClass('shadow');

    });
    $('.hero-cta-form-wrapper').on('click',function(event){
        if (!$(event.target).closest("form").length) {
            $(".hero-cta-form-wrapper").fadeOut();
            siteBody.removeClass('shadow');
            siteHtml.removeClass('shadow');
        }
    });

    // Home contact form
    $('#hc_form').on('submit',function(e){
        e.preventDefault();
        var $that = $(this);
        var nonce = $that.find("input[name='hc_nonce']").val();
        var customerEmail = $that.find("input[name='hc_email']").val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(customerEmail);
        var userName = $that.find("input[name='hc_name']").val();
        if( customerEmail === '' ){
            $that.find("input[name='hc_email']").css({'border':'1px solid red'});
            $("#hc_email_error").text('Email is required');
        }
        else if( check_mail !== true ){
            $that.find("input[name='hc_email']").css({'border':'1px solid red'});
            $("#hc_email_error").text('Not valid email');

        }else{
            $that.find("input[name='hc_email']").css({'border':'1px solid white'});
            $("#hc_email_error").text('');
        }
        if( userName == '' ){
            $that.find("input[name='hc_name']").css({'border':'1px solid red'});
            $("#hc_name_error").text('Name is required');
        }else{
            $that.find("input[name='hc_name']").css({'border':'1px solid white'});
            $("#hc_name_error").text('');
        }
        if( check_mail == true && userName !== '' ){
            $that.find("input[name='hc_email']").css({'border':'1px solid white'});
            $that.find("input[name='hc_name']").css({'border':'1px solid white'});
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'home_contact_form',
                    nonce: nonce,
                    user_name: userName,
                    user_email: customerEmail,
                    captcha: grecaptcha.getResponse()
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    console.log(result);
                    if(res.status === 'success'){
                        $that[0].reset();
                        grecaptcha.reset();
                        $('.thank-popup-wrapper').fadeIn();
                        setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 3000);
                    }
                    else if(res.status === 'user_already_exists'){
                        $("input[name='hc_email']").css({'border':'1px solid red'});
                        $("#hc_email_error").text('Email: '+customerEmail+' is already registered');
                        grecaptcha.reset();
                    }
                    else if(res.status === 'post_not_set'){
                        $('.recaptcha-error-msg').html('<p>Oops... Validation is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                    else if(res.status === 'failed'){
                        $('.recaptcha-error-msg').html('<p>Oops... Recaptcha Validation failed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });

    // Contact us contact form + mask
    if( document.getElementById('cu_phone') ){
        document.getElementById('cu_phone').addEventListener('input', function (e) {
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
            e.target.value = !x[2] ? x[1] : '+' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
        });
    }

    $('#cu_form').on('submit',function(e){
        e.preventDefault();
        var $that = $(this);
        var nonce = $that.find("input[name='cu_nonce']").val();
        var customerEmail = $that.find("input[name='cu_email']").val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(customerEmail);
        var userName = $that.find("input[name='cu_name']").val();
        var userCompany = $that.find("input[name='cu_company']").val();
        var userSubject = $that.find("input[name='cu_subject']").val();
        var userMessage = $that.find("textarea[name='cu_message']").val();
        var userPhone = $that.find("input[name='cu_phone']").val();
        var phone_pattern = /^\+?([0-9]{1})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var check_phone = phone_pattern.test(userPhone);
        if( userPhone !== '' && check_phone !== true ){
            $that.find("input[name='cu_phone']").css({'border':'1px solid red'});
            $("#cu_phone_error").text('Not valid phone');
            setTimeout(function(){$that.find("input[name='cu_phone']")}, 3000);
        }
        else if (userPhone === ''){
            $that.find("input[name='cu_phone']").css({'border':'1px solid red'});
        }
        else{
            $that.find("input[name='cu_phone']").css({'border':'1px solid #F4F4F4'});
            $("#cu_phone_error").text('');
        }
        if( customerEmail === '' ){
            $that.find("input[name='cu_email']").css({'border':'1px solid red'});
            setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
            $('#cu_email_error').text('');
        }
        else if( check_mail !== true ){
            $that.find("input[name='cu_email']").css({'border':'1px solid red'});
            setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
            $('#cu_email_error').text('Not valid email');
        }else{
            $that.find("input[name='cu_email']").css({'border':'1px solid #F4F4F4'});
            $('#cu_email_error').text('');
        }
        if( userName == '' ){
            $that.find("input[name='cu_name']").css({'border':'1px solid red'});
            setTimeout(function(){$that.find("input[name='cu_name']")}, 3000);
        }else{
            $that.find("input[name='cu_name']").css({'border':'1px solid #F4F4F4'});
        }
        if( check_phone == true && check_mail == true && userName !== '' ){
            $that.find("input[name='cu_email']").css({'border':'1px solid #F4F4F4'});
            $that.find("input[name='cu_name']").css({'border':'1px solid #F4F4F4'});
            $that.find("input[name='cu_phone']").css({'border':'1px solid #F4F4F4'});
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'contact_us_form',
                    nonce: nonce,
                    user_name: userName,
                    user_email: customerEmail,
                    user_phone: userPhone,
                    user_company: userCompany,
                    user_subject: userSubject,
                    user_message: userMessage,
                    captcha: grecaptcha.getResponse()
                },
                success: function (result) {
                    console.log(result);
                    var res = JSON.parse(result);
                    if(res.status === 'success'){
                        $that[0].reset();
                        grecaptcha.reset();
                        $('.thank-popup-wrapper').fadeIn();
                        setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 3000);
                    }
                    else if(res.status === 'failed'){
                        grecaptcha.reset();
                        $('.recaptcha-error-msg').html('<p style="color:red;">Oops... Recaptcha is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                    else if(res.status === 'post_or_nonce_not_set'){
                        grecaptcha.reset();
                        $('.recaptcha-error-msg').html('<p style="color:red;">Oops... Validation is not passed!</p>');
                        setTimeout(function(){
                            $('.recaptcha-error-msg').html('');
                        }, 3000);
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });

    $('.order-btn-js').on('click',function(){
       var propositionId = $(this).data('order');
        siteBody.addClass('shadow');
        siteHtml.addClass('shadow');
       $('.footer-order-popup').css({'display':'flex'});
       $('#ff_order').val(propositionId);
    });
    $('.footer-order-form-close-js').on('click',function(){
        $('.footer-order-popup').fadeOut();
        siteBody.removeClass('shadow');
        siteHtml.removeClass('shadow');
    });
    $('.footer-order-popup').on('click',function(event){
        if (!$(event.target).closest("form").length) {
            $(".footer-order-popup").fadeOut();
            siteBody.removeClass('shadow');
            siteHtml.removeClass('shadow');
        }
    });
    $('#order_form').on('submit',function(e){
        e.preventDefault();
        var $that = $(this);
        var nonce = $that.find("input[name='order_nonce']").val();
        var orderId = $that.find("input[name='ff_order']").val();
        var orderClientEmail = $that.find("input[name='ff_email']").val();
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(orderClientEmail);
        if( orderClientEmail === '' ){
            $that.find("input[name='ff_email']").css({'border':'1px solid red'});
            $("#ff_email_error").text('Email is required');
        }
        else if( check_mail !== true ){
            $that.find("input[name='ff_email']").css({'border':'1px solid red'});
            $("#ff_email_error").text('Not valid email');
        }else{
            $that.find("input[name='ff_email']").css({'border':'1px solid #ccc'});
            $("#ff_email_error").text('');
        }
        if( check_mail === true && orderId !== '' ){
            $that.find("input[name='ff_email']").css({'border':'1px solid #ccc'});
            $.ajax({
                type: 'POST',
                url: localizedScript.url,
                data:{
                    action: 'footer_order_form',
                    nonce: nonce,
                    order_id: orderId,
                    user_email: orderClientEmail
                },
                success: function (result) {
                    var res = JSON.parse(result);
                    console.log(result);
                    if(res.status === 'success'){
                        $that[0].reset();
                        $('.thank-popup-wrapper').fadeIn();
                        setTimeout(function(){
                            $('.footer-order-popup').fadeOut();
                            $('.thank-popup-wrapper').fadeOut();
                            siteBody.removeClass('shadow');
                            siteHtml.removeClass('shadow');
                        }, 3000);
                    }
                    else if(res.status === 'failed'){
                        $('#ff_email_error').text('Oops... Something went wrong!');
                        setTimeout(function(){
                            $('#ff_email_error').text('');
                        }, 3000);
                    }
                },
                error: function(jqxhr, status, exception) {
                    console.log('Exception:', exception);
                }
            });
        }
    });

    $("#footer_form").on("submit",function(e){
        e.preventDefault();
        var $thanksPopup = $('.thank-popup-wrapper');
        var $that=$(this);
        var footer_form_nonce = $('#footer_form_nonce').val();
        var $nameInput = $('input[name="user_name"]');
        var $emailInput = $('input[name="user_mail"]');
        var $phoneInput = $('input[name="user_phone"]');
        var userName = $nameInput.val();
        var userEmail = $emailInput.val();
        var emailTest =/[0-9a-z_]+@[0-9a-z_]+\.[a-z]{2,5}/i.test(userEmail);
        var userPhone = $phoneInput.val();
        if( 2<=userName.length&&$nameInput.css({border:"1px solid #ccc"}),
        9<=userPhone.length&& $phoneInput.css({border:"1px solid #ccc"}),
        true===emailTest&&$emailInput.css({border:"1px solid #ccc"}),
        2<=userName.length&&9<=userPhone.length&&true===emailTest ){

            $nameInput.css({border:"1px solid #ccc"}),
            $emailInput.css({border:"1px solid #ccc"}),
            $phoneInput.css({border:"1px solid #ccc"});
            var a=$(this).serializeArray();
            $.ajax({
                    url:window.localizedScript.url,
                    data:{
                    action:"footerform",
                    nonce: footer_form_nonce,
                    footerform_val:a
                    },
                type:"POST",
                success:function(e){
                    if( JSON.parse(e).status === "sent"){
                        console.log(JSON.parse(e).msg);
                        $that[0].reset();
                        $thanksPopup.show();
                        setTimeout(function(){ $thanksPopup.hide()},3000);
                    }else if(JSON.parse(e).status === "failed") {
                        console.log(JSON.parse(e).msg);
                    }else if(JSON.parse(e).status === "not sent") {
                        console.log(JSON.parse(e).msg);
                    }
                }
            })
        }else{
            userName.length<2&&$nameInput.css({border:"1px solid red"}),
            false===emailTest&&$emailInput.css({border:"1px solid red"}),
            userPhone.length<9&&$phoneInput.css({border:"1px solid red"})
        }

    });

});
