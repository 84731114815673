/**
 * Created by Ростислав on 20.02.2020.
 */

/*
 * Substring matcher
 * Returns function
 */
var substringMatcher = function(strs) {
    return function findMatches(q, cb) {
        var matches, substringRegex;
        matches = [];
        // regex used to determine if a string contains the substring `q`
        substringRegex = new RegExp(q, 'i');
        // iterate through the pool of strings and for any string that
        // contains the substring `q`, add it to the `matches` array
        $.each(strs, function(i, str) {
            if (substringRegex.test(str)) {
                matches.push(str);
            }
        });
        cb(matches);
    };
};


$(document).ready(function(){






    /*
     * Get cities by ajax query
     */
    function ajax_get_cities(){
        $('#np_city').addClass('typeahead');
        $.ajax({
            type: 'POST',
            url: checkoutScript.url,
            data:{
                action: 'npgetcities'
            },
            success: function (result) {
                var states = JSON.parse(result);
                console.log(states);

                $('.typeahead').typeahead({
                        hint: true,
                        highlight: true,
                        minLength: 1
                    },
                    {
                        name: 'states',
                        limit: 50,
                        source: substringMatcher(states)
                    });
            }
        });
    }
    /* Run ajax get cities when document is ready by default */
    ajax_get_cities();

    /*
     * Event change input np_city value - ajax sending np_city value
     * On success trigger update_checkout - ajax updating
     */
    $("#np_city").on("change", function(){

        if( $('#np_office').length > 0 ){
            var np_city = $(this).val();
            // clear previous value and array
            $("input[name='np_office']").val('');
            // Clear previous typeahead data
            $('#np_office').typeahead('destroy');
            $.ajax({
                type: 'POST',
                url: checkoutScript.url,
                data:{
                    action: 'npgetoffices',
                    np_city_input_val: np_city
                },
                success: function (result) {
                    console.log(np_city);
                    // updatig np city checkout field on input changed
                    $('body').trigger('update_checkout');
                    var trueOffices = JSON.parse(result);
                    console.log(trueOffices);
                    $('#np_office').typeahead({
                            hint: true,
                            highlight: true,
                            minLength: 1
                        },
                        {
                            name: 'trueOffices',
                            limit: 50,
                            source: substringMatcher(trueOffices)
                        });
                }
            });
        }

    });

    if( document.getElementById('client_tel') ){
        document.getElementById('client_tel').addEventListener('input', function (e) {
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/);
            e.target.value = !x[2] ? x[1] : '+' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
        });
    }

    /**
     * Check tel input field
     * @param number
     * @param input
     * @param err
     * @returns {boolean}
     */
    function checkTelephone(number,input,err){
        var phone_pattern = /^\+?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var check_phone = phone_pattern.test(number);
        if( number !== '' && check_phone !== true ){
            input.css({'border':'1px solid red'});
            err.text('Не валідний номер');
            return false;
        }
        else if (number === ''){
            input.css({'border':'1px solid red'});
            err.text('Поле телефон порожнє');
            return false;
        }
        else{
            input.css({'border':'1px solid #d3d3d3'});
            err.text('');
            return true;
        }
    }

    /**
     * Check email field
     * @param email
     * @param input
     * @param err
     * @returns {boolean}
     */
    function checkEmail(email,input,err){
        var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        var check_mail = mail_pattern.test(email);
        if( email !== '' && check_mail !== true ){
            input.css({'border':'1px solid red'});
            err.text('Не валідний email');
            return false;
        }
        else if ( email === ''){
            input.css({'border':'1px solid red'});
            err.text('Поле email порожнє');
            return false;
        }
        else{
            input.css({'border':'1px solid #d3d3d3'});
            err.text('');
            return true;
        }
    }

    /**
     *
     * @param field
     * @param input
     * @param err
     * @returns {boolean}
     */
    function checkInputTextField(field,input,err){
        if( field === '' ){
            input.css({'border':'1px solid red'});
            err.text('Не заповнене поле');
            return false;
        }else{
            input.css({'border':'1px solid #d3d3d3'});
            err.text('');
            return true;
        }
    }

    function ajaxSender(mainData,checkoutData){
        $.ajax({
            type: 'POST',
            url: checkoutScript.url,
            data:{
                action: 'checkout_form',
                nonce: mainData.nonce,
                checkout_method: mainData.checkout_method,
                total_price: mainData.total_price,
                id_products: mainData. id_products,
                payment_method: mainData.payment_method,
                client_name: mainData.client_name,
                client_lastname: mainData.client_lastname,
                client_tel: mainData.client_tel,
                client_email: mainData.client_email,
                checkout_data: JSON.stringify(checkoutData)
            },
            success: function (result) {
                var res = JSON.parse(result);
                if(res.status === 'success'){
                    var dataRemove = mainData.id_products;
                    var arrRemove = dataRemove.split(',');
                   // Removing products from cookies
                    var x;
                    for ( x in arrRemove ){
                        if(arrRemove.hasOwnProperty(x)){
                            deleteCookie('product_'+ arrRemove[x]);
                        }
                    }
                    $('#checkout_form')[0].reset();
                    $('.checkout-thank-msg').fadeIn().css({'display':'flex'});
                    setTimeout(function(){$('.checkout-thank-msg').fadeOut()}, 6000);
                    window.location.replace($('body').data('home-url'));
                }
                else if(res.status === 'nonce_not_set'){
                    $('#validation_err').text('Валідація не пройдена');
                }
            },
            error: function(jqxhr, status, exception) {
                console.log('Exception:', exception);
            }
        });
    }

    /**
     * Checkout form submit controller
     */
    $('#checkout_form').on('submit',function(e){

        e.preventDefault();
        var nonce       = $('#ch_f_nonce').val();
        var chMethod    = $(this).data('method');
        var totalPrice  = $('#total_price').val();
        var idProducts  = $('#id_products').val();
        var paymentMethod = $("input[name='payment_method']:checked").val();
        var clientName  = $('#client_name').val();
        var clientLastName = $('#client_last_name').val();
        var clientTel   = $('#client_tel').val();
        var clientEmail = $('#client_email').val();
        var checkClientName = checkInputTextField(clientName,$('#client_name'),$('#client_name_err'));
        var checkClientLastName = checkInputTextField(clientLastName,$('#client_last_name'),$('#client_last_name_err'));
        var checkPhone = checkTelephone(clientTel,$('#client_tel'),$('#client_tel_err'));
        var checkClientEmail = checkEmail(clientEmail,$('#client_email'),$('#client_email_err'));
        var mainData = {
            nonce: nonce,
            checkout_method: chMethod,
            total_price: totalPrice,
            id_products: idProducts,
            payment_method: paymentMethod,
            client_name: clientName,
            client_lastname: clientLastName,
            client_tel: clientTel,
            client_email: clientEmail
        };
        var checkoutData = {};
        switch(chMethod){
            case 'novaposhta':
                var npCity = $('#np_city').val();
                var npStreet = $('#np_street').val();
                var npHouseNumber = $('#np_house_number').val();
                var npFlatNumber = $('#np_flat_number').val();
                var checkNpCity = checkInputTextField(npCity,$('#np_city'),$('#np_city_err'));
                var checkNpStreet = checkInputTextField(npStreet,$('#np_street'),$('#np_street_err'));
                var checkNpHouseNumber = checkInputTextField(npHouseNumber,$('#np_house_number'),$('#np_house_number_err'));
                checkoutData = {
                    npCity:npCity,
                    npStreet:npStreet,
                    npHouseNumber:npHouseNumber,
                    npFlatNumber:npFlatNumber
                };
                if( checkNpCity && checkNpStreet && checkNpHouseNumber && checkClientName && checkClientLastName && checkPhone && checkClientEmail ){
                    ajaxSender(mainData,checkoutData);
                }
            break;
            case 'novaposhta_office':
                var npCity = $('#np_city').val();
                var npOffice = $('#np_office').val();
                var checkNpCity = checkInputTextField(npCity,$('#np_city'),$('#np_city_err'));
                var checkNpOffice = checkInputTextField(npCity,$('#np_office'),$('#np_office_err'));
                checkoutData = {
                    npCity:npCity,
                    npOffice:npOffice
                };
                if( checkNpCity && checkNpOffice && checkClientName && checkClientLastName && checkPhone && checkClientEmail ){
                    ajaxSender(mainData,checkoutData);
                }
                break;
            case 'ukrpost':
                var upIndex = $('#up_index').val();
                var upCountry = $('#up_country').val();
                var upCity = $('#up_city').val();
                var upRegion = $('#up_region').val();
                var upStreet = $('#up_street').val();
                var upHouseNumber = $('#up_house_number').val();
                var upFlatNumber = $('#up_flat_number').val();

                var checkupIndex = checkInputTextField(upIndex,$('#up_index'),$('#up_index_err'));
                var checkupCountry = checkInputTextField(upCountry,$('#up_country'),$('#up_country_err'));
                var checkupCity = checkInputTextField(upCity,$('#up_city'),$('#up_city_err'));
                var checkupRegion = checkInputTextField(upRegion,$('#up_region'),$('#up_region_err'));
                var checkupStreet = checkInputTextField(upStreet,$('#up_street'),$('#up_street_err'));
                var checkupHouseNumber = checkInputTextField(upHouseNumber,$('#up_house_number'),$('#up_house_number_err'));

                checkoutData = {
                    upIndex:upIndex,
                    upCountry:upCountry,
                    upCity:upCity,
                    upRegion:upRegion,
                    upStreet:upStreet,
                    upHouseNumber:upHouseNumber,
                    upFlatNumber:upFlatNumber
                };
                if( checkupIndex && checkupCountry && checkupCity && checkupRegion && checkupStreet && checkupHouseNumber && checkClientName && checkClientLastName && checkPhone && checkClientEmail ){
                    ajaxSender(mainData,checkoutData);
                }
                break;
            default:
                checkoutData = {};
                break;
        }
















    });




});

