import * as $ from 'jquery';

if( $('#range_slider').length ){

    window.noUiSlider = require('nouislider');


    $( document ).ready(function() {


        var slider = document.getElementById('range_slider');
        var priceFrom = parseInt($('#price_from').val());
        var priceTo = parseInt($('#price_to').val());
        if (typeof (noUiSlider) !== 'undefined') {
            noUiSlider.create(slider, {
                start: [priceFrom, priceTo],
                step: 1,
                tooltips: [true, true],
                connect: true,
                /* pips: {
                     mode: 'steps',
                     stepped: true,
                     density: 10
                 },*/
                range: {
                    'min': 1000,
                    'max': 50000
                }
            });

            var inputPriceFrom = document.getElementById('price_from');
            var inputPriceTo = document.getElementById('price_to');


            var inputsPrices = [inputPriceFrom, inputPriceTo];
            slider.noUiSlider.on('change', function (values, handle) {
                var priceFromCurrentValue = $(inputPriceFrom).val();
                var priceToCurrentValue = $(inputPriceTo).val();

                if (parseInt(priceFromCurrentValue) !== parseInt(values[0])) {
                    $(inputPriceFrom).val(values[0]).trigger("change");
                }
                if (parseInt(priceToCurrentValue) !== parseInt(values[1])) {
                    $(inputPriceTo).val(values[1]).trigger("change");
                }

                //inputsPrices[handle].value = values[handle];
            });

            inputPriceFrom.addEventListener('change', function () {
                slider.noUiSlider.set([this.value, null]);
            });

            inputPriceTo.addEventListener('change', function () {
                slider.noUiSlider.set([null, this.value]);
            });
        }

    });



}



